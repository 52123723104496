<template>
  <div>
    <v-toolbar flat color="transparent">
      <v-spacer></v-spacer>
      <v-chip>
        <v-icon small>search</v-icon>
        <v-text-field
          placeholder="Search"
          class="mb-1"
          hide-details
          rounded
          clearable
          dense
          style="width: 300px"
          v-model="search"
        ></v-text-field>
      </v-chip>
    </v-toolbar>
    <ag-grid-vue
      groupDisplayType="multipleColumns"
      :animateRows="false"
      :rowGroupPanelShow="'always'"
      :autoSizeStrategy="{
        type: 'fitGridWidth',
        defaultMinWidth: 125,
        columnLimits: [
          {
            colId: 'documentationStatus',
            minWidth: 120,
            width: 120,
          },
          {
            colId: 'fileNumber',
            minWidth: 180,
            width: 180,
          },
          {
            colId: 'vesselVoyage',
            minWidth: 200,
          },
          {
            colId: 'shipperName',
            minWidth: 200,
          },
          {
            colId: 'onBehalfShipperName',
            minWidth: 200,
          },
          {
            colId: 'consigneeName',
            minWidth: 200,
          },
        ],
      }"
      style="width: 98%; height: 85vh"
      :enableRangeSelection="true"
      :allowContextMenuWithControlKey="true"
      :getContextMenuItems="getContextMenuItems"
      :columnDefs="columns"
      :rowData="files"
      :suppressAggFuncInHeader="true"
      :defaultColDef="defaultColDef.def"
      :sideBar="sidebar"
      :statusBar="statusBar"
      :initialState="initialState"
      :autoGroupColumnDef="autoGroupColumnDef"
      @state-updated="onStateUpdated"
      @grid-ready="onGridReady"
    >
    </ag-grid-vue>
  </div>
</template>
<script>
/* eslint-disable vue/no-unused-components */
import ActionPanel from "./FileComponents/ActionPanel.vue";
import { AgGridVue } from "ag-grid-vue";
import CargoQuantity from "./FileComponents/CargoQuantity.vue";
import FileNumber from "./FileComponents/FileNumber.vue";
import FileStatus from "./FileComponents/FileStatus.vue";
import OrganisationName from "../GridComponents/Organisation.vue";
import PortCell from "../GridComponents/Port.vue";
export default {
  name: "FileTable",
  components: {
    ActionPanel,
    AgGridVue,
    CargoQuantity,
    FileNumber,
    FileStatus,
    OrganisationName,
    PortCell,
  },
  data: () => ({
    autoGroupColumnDef: {
      //   headerName: (params) => { return `${params.colDef.headerName} Group Column`},
      headerValueGetter: (params) => {
        return params.colDef.headerName ? params.colDef.headerName : "No Value";
      },
      minWidth: 200,
      cellRendererParams: {
        // suppressCount: true,
      },
    },
    columns: [
      {
        headerName: "File Status",
        field: "documentationStatus",
        cellRenderer: "FileStatus",
      },
      {
        headerName: "File No.",
        field: "fileNumber",
        enableRowGroup: false,
        aggFunc: "count",
        cellRenderer: "FileNumber",
        valueGetter: (params) => {
          return params.data ? params.data.shipmentFile.fileNumber || "" : "";
        },
        cellRendererParams: (p) => {
          return p.data
            ? {
                shipmentFile: p.data.shipmentFile,
                movementType: p.data.movementType,
              }
            : null;
        },
      },
      {
        headerName: "Type",
        field: "type",
      },
      {
        headerName: "Carrier",
        field: "shippingLineName",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.shippingLine
            ? {
                name: p.data.shippingLine.name,
                alias: p.data.shippingLine.alias,
                logo: p.data.shippingLine.logo,
                friendlyName: p.data.shippingLine.friendlyName,
              }
            : null;
        },
      },
      {
        headerName: "Cargo",
        field: "containerQuantity",
        cellRenderer: "CargoQuantity",
        aggFunc: "sum",
        cellRendererParams: (p) => {
          return {
            shipment: p.data,
          };
        },
        valueGetter: (params) => {
          return params.data ? params.data.containerQuantity || 0 : 0;
        },
      },
      {
        headerName: "Pallets",
        field: "palletQuantity",
        aggFunc: "sum",
      },
      {
        headerName: "Shipper",
        field: "shipperName",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.shipper
            ? {
                name: p.data.shipper.name,
                alias: p.data.shipper.alias,
                logo: p.data.shipper.logo,
                friendlyName: p.data.shipper.friendlyName,
              }
            : null;
        },
      },
      {
        headerName: "On Behalf Shipper",
        field: "onBehalfShipperName",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.onBehalfShipper
            ? {
                name: p.data.onBehalfShipper.name,
                alias: p.data.onBehalfShipper.alias,
                logo: p.data.onBehalfShipper.logo,
                friendlyName: p.data.onBehalfShipper.friendlyName,
              }
            : null;
        },
      },
      {
        headerName: "Receiver",
        field: "consigneeName",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.consignee
            ? {
                name: p.data.consignee.name,
                alias: p.data.consignee.alias,
                logo: p.data.consignee.logo,
                friendlyName: p.data.consignee.friendlyName,
              }
            : null;
        },
      },
      {
        headerName: "Vessel/Voyage",
        field: "vesselVoyage",
      },
      {
        headerName: "Event",
        field: "event",
      },
      {
        headerName: "POL",
        field: "portOfLoadValue",
        cellRenderer: "PortCell",
        cellRendererParams: (p) => {
          return {
            locode: p.data ? p.data.portOfLoadValue : null,
            name: p.data ? p.data.portOfLoadCity : null,
          };
        },
      },
      {
        headerName: "POD",
        field: "portOfDischargeValue",
        cellRenderer: "PortCell",
        cellRendererParams: (p) => {
          return {
            locode: p.data ? p.data.portOfDischargeValue : null,
            name: p.data ? p.data.portOfDischargeCity : null,
          };
        },
      },
      {
        headerName: "Final Dest.",
        field: "finalDestinationValue",
        cellRenderer: "PortCell",
        cellRendererParams: (p) => {
          return {
            locode: p.data ? p.data.finalDestinationValue : null,
            name: p.data ? p.data.finalDestinationCity : null,
          };
        },
      },
      {
        headerName: "ETD/ATD",
        field: "etd",
      },
      {
        headerName: "ETA/ATA",
        field: "eta",
      },
    ],
    defaultColDef: {
      def: {
        sortable: true,
        resizable: true,
        filter: true,
        enableRowGroup: true,
        enableValue: true,
        enablePivot: true,
        flex: 1,
        minWidth: 100,
        cellClass: "d-flex align-center",
        cellStyle: { textAlign: "center", fontSize: "11px" },
      },
    },
    files: null,
    gridApi: null,
    gridColumnApi: null,
    initialState: {},
    loading: true,
    search: "",
    searchTimeout: null,
    sidebar: {},
    statusBar: {
      statusPanels: [
        {
          statusPanel: "agTotalRowCountComponent",
          align: "left",
        },
        {
          statusPanel: "agFilteredRowCountComponent",
        },
        {
          statusPanel: "agSelectedRowCountComponent",
        },
        {
          statusPanel: "agAggregationComponent",
        },
      ],
    },
  }),
  watch: {
    search(val) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(async () => {
        this.gridApi.updateGridOptions({ quickFilterText: val });
      }, 400);
    },
  },
  created() {
    this.sidebar = {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          minWidth: 225,
          maxWidth: 225,
          width: 225,
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
        {
          id: "actions",
          labelDefault: "Actions",
          labelKey: "actions",
          iconKey: "chart",
          toolPanel: "ActionPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
          toolPanelParams: {
            resetTable: this.resetTable,
            exportExcel: () => {
              this.gridApi.exportDataAsExcel();
            },
            exportCSV: () => {
              this.gridApi.exportDataAsCsv();
            },
          },
        },
      ],
      defaultToolPanel: "",
    };
    if (localStorage.getItem("fileTableState_"+this.$store.state.currentOrg.id)) {
      let state = JSON.parse(localStorage.getItem("fileTableState_"+this.$store.state.currentOrg.id));
      if (typeof state === "object" && state !== null) {
        this.initialState = state;
      }
    }
    this.getFiles();
  },
  methods: {
    getContextMenuItems(params) {
      let result = [];
      if (!params.node.group) {
        result = [
          {
            name: "Open File",
            icon: '<span class="ag-icon ag-icon-arrows" unselectable="on" role="presentation"></span>',
            action: () => {
              if (
                params.node &&
                params.node.data &&
                params.node.data.shipmentReference
              ) {
                this.$router.push({
                  path: "/documentation/" + params.node.data.shipmentReference,
                });
              } else {
                this.$message.error(
                  "Please select a single row to view the file"
                );
              }
            },
          },
          "separator",
          {
            name: "Add to Filter",
            icon: '<span class="ag-icon ag-icon-filter" unselectable="on" role="presentation"></span>',
            action: () => {
              if (
                params.node &&
                params.node.data
              ) {
                let filter = this.gridApi.getFilterModel();
                if(filter[params.column.colId]) {
                  filter[params.column.colId].values.push(params.value);
                } else {
                  filter[params.column.colId] = {
                    values: [params.value]
                  }
                }
                this.gridApi.setFilterModel(filter);
              } else {
                this.$message.error(
                  "Please select a single row to view the file"
                );
              }
            },
          },
          "separator",
          "copy",
          "copyWithHeaders",
          "export",
          "chartRange",
        ];
      } else {
        result = [
          "copy",
          "copyWithHeaders",
          "export",
          "chartRange",
        ];
      }

      return result;
    },
    async getFiles() {
      this.loading = true;
      try {
        this.setLoading(true);
        let files = await this.$API.getShipments({
          params: {
            filter: {
              documentationStatus: "OPEN",
            },
          },
        });
        this.files = files.data;
        this.loading = false;
        this.setLoading(false);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
        this.setLoading(false);
      }
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    onStateUpdated(state) {
      localStorage.setItem("fileTableState_"+this.$store.state.currentOrg.id, JSON.stringify(state.state));
    },
    resetTable() {
      this.gridApi.setFilterModel(null);
      this.gridApi.setRowGroupColumns([]);
      this.gridApi.applyColumnState({state: this.columns});
      this.gridApi.refreshCells({ force: true });
    },
    setLoading(val) {
      this.loading = val;
      if (this.gridApi) {
        this.gridApi.setGridOption("loading", val);
      } else {
        setTimeout(() => {
          this.gridApi.setGridOption("loading", val);
        }, 500);
      }
    },
  },
};
</script>
<style lang="scss">
@import "~ag-grid-community/styles/ag-grid.css";
</style>